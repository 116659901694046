$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.template1 {
	color: var(--header-text) !important;
	font-family: "Ortica";
	padding-top: 200px;
	&__top {
		&__heading {
			@media screen and (max-width: $breakpoint-tablet) {
				font-size: 2.3rem;
			}
			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 1.7rem;
			}
		}
		&__details {
			margin-top: 50px;
			justify-content: space-between;
			margin-bottom: 70px;
			&__right {
				width: 45%;
				&__header {
					font-family: "Ortica";
					color: var(--header-text);
					font-size: 2rem;
					margin-bottom: 1.5rem;
				}
				&__form {
					input {
						background-color: #f5f5f5;
						color: var(--black);
						border: transparent;
						padding: 20px;
						margin-bottom: 25px;
					}
					textarea {
						background-color: #f5f5f5;
						color: var(--black);
						padding: 20px;
						border: transparent;
						margin-bottom: 25px;
						height: 120px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-tablet) {
	.template1 {
		&__top {
			&__details {
				flex-direction: column;

				&__right {
					width: 100%;
				}
			}
		}
	}
	.button {
		width: 100%;
	}
}

.toast {
	width: 100%;
	margin-top: 20px;
	padding: 1rem;
	background-color: #ffffff;
	border-radius: 0.5rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	svg {
		height: 1.25rem;
		color: #4a90e2;
		width: 1.25rem;
		color: #6a7dfe;
		transform: rotate(45deg);
	}
	div {
		padding-left: 1rem;
		font-weight: normal;
		font-size: 0.875rem;
		color: #8eb28e;
	}
}
