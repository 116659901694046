.rotateDiv {
	width: fit-content;
	position: relative;
	.rotate {
		// width: 8vw;
		margin: 0px 4%;
		animation: rotation 4s infinite linear;
	}
    .arrow{
        position: absolute;
 
    }
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
