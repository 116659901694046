$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.twoArrows {
	justify-content: space-between;
	padding: 120px 6% 70px;
	&__middleText {
		width: 60%;
		&__paragraph {
			color: var(--body-text);
			font-size: 1.1rem;
			line-height: 1.6;
			margin: 35px 0px;
			text-align: center;
			width: 75%;
		}
		.mobileRotatingArrow {
			display: none;
		}
	}
}

@media screen and (max-width: $breakpoint-tablet) {
	.twoArrows {
		.arrow {
			display: none;
		}
		&__middleText {
			width: 100%;
			&__paragraph {
				width: 100%;
			}
			.heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
			}
			.button {
				margin-bottom: 50px;
				width: 100%;
			}
			.mobileRotatingArrow {
				display: flex;
			}
		}
	}
}
