.carousel {
	margin-bottom: 30px;
	.content {
		position: relative;
		height: 400px;
		img {
			width: 100%;
			height: 100%;
		}
		p {
			margin-top: 1rem;
		}
		&__arrow {
			position: absolute;

			width: 10%;
			top: 42%;
		}
		&__leftArrow {
			left: 10px;
		}
		&__rightArrow {
			right: 10px;
		}
	}
}
.originalContent {
	margin: 0px 5%;
	height: 450px;
	img {
		width: 100%;
		height: 100%;
	}
	p {
		margin-top: 30px;
	}
}
