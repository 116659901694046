$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.template3 {
	background-color: var(--white);
	padding: 0 !important;
	&__left {
		width: 50%;
		padding: 13% 6%;
		&__paragraph {
			text-transform: uppercase;
			color: var(--black);
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 15px;
		}
		&__heading {
			font-size: 2.2rem;
		}
		&__suites {
			&__suite {
				padding: 20px;
				border-right: 6px solid var(--selection-background);
				transition: 0.9s;
				&:hover {
					background-color: var(--selection-background);
					border-right: 6px solid var(--black);
					transition: 0.9s;
				}
				&__image {
					width: 30%;
					position: relative;
					img {
						width: 100%;
					}
					> p {
						text-transform: capitalize;
						color: var(--white);
						padding: 4px;
						font-size: 12px;
						background-color: var(--brand-primary-1);
						width: fit-content;
						position: absolute;
						top: 5%;
						left: -5%;
					}
				}
				&__info {
					margin-left: 20px;
					&__header {
						font-size: 1.3rem;
					}
					> p {
						color: var(--body-text);
						span {
							color: var(--header-text);
							font-weight: 700;
						}
					}
				}
			}
		}
	}
	&__right {
		width: 50%;
		position: relative;
		&__rotatingArrow {
			position: absolute;
			bottom: 10%;
			right: 10%;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.template3 {
		flex-direction: column;
		justify-content: space-between;
		&__left {
			padding: 180px 6% 50px !important;
			width: 100%;
			padding: 0;
			margin-bottom: 45px;
			&__heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
				line-height: 1.2;
				margin: 20px 0px 50px;
			}
		}
		&__right {
			width: 100%;
			&__rotatingArrow {
				position: absolute;
				bottom: 5%;
				right: 5%;
			}
			img {
				margin-bottom: -4px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-bigPhone) {
	.template3 {
		&__left {
			padding: 180px 6% 50px !important;
			width: 100%;
			padding: 0;
			margin-bottom: 45px;
			&__heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
				line-height: 1.2;
				margin: 20px 0px 50px;
			}
			&__suites {
				&__suite {
					&__image {
						display: none;
					}
				}
			}
		}
		&__right {
			width: 100%;
			&__rotatingArrow {
				position: absolute;
				bottom: 5%;
				right: 5%;
			}
			img {
				margin-bottom: -4px;
			}
		}
	}
}
