$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.template2 {
	color: var(--header-text) !important;
	font-family: "Ortica";
	padding-top: 200px;
	&__top {
		&__heading {
			@media screen and (max-width: $breakpoint-tablet) {
				font-size: 2.3rem;
			}
			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 1.7rem;
			}
		}
		&__details {
			margin-top: 50px;
			justify-content: space-between;
			margin-bottom: 70px;
			&__left {
				width: 55%;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-gap: 15px;
				@media screen and (max-width: 865px) {
					grid-template-columns: 1fr 1fr;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.template2 {
		&__top {
			&__details {
				flex-direction: column;
				&__left {
					width: 100%;
					margin-bottom: 60px;
				}
				&__right {
					width: 100%;
				}
			}
		}
	}
}
