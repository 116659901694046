.paragraph {
	max-width: 20%;
	p {
		line-height: 1.6;
		font-size: 0.925rem;
        text-transform: uppercase;
	
		
	}
	.dot {
		margin-top: 12px;
	}
}
