$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.template2 {
	background-color: var(--brand-primary-3);
	justify-content: space-between;
	&__left {
		width: 60%;
		&__header {
			width: 100%;
			line-height: 1.2;
		}
		&__paragraph {
			margin: 50px 0px 60px 0px;
			width: 80%;
			p {
				margin: 25px 0px;
				font-size: 14px;
				line-height: 1.6;
				word-spacing: 2px;
			}
		}
	}
	&__right {
		position: relative;
		width: 40%;
		height: 500px;
		&__layer {
			position: absolute;
			right: 10%;
			background-color: var(--brand-primary-5);
			height: 100%;
			width: 160px;
			align-items: flex-end;
			&__div {
				position: absolute;
				left: 9%;
				bottom: 2%;
			}
		}

		&__img {
			position: absolute;
			top: 10%;
			width: 100%;
			height: 60%;
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.template2 {
		padding: 180px 6% 120px;
		flex-direction: column;
		align-items: center;
		&__left {
			width: 100%;
			&__header {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
			}
			&__paragraph {
				width: 100%;
			}
			&__button {
				width: 100%;
			}
		}
		&__right {
			margin-top: 60px;
			width: 100%;
			height: 600px;
			justify-content: center;
			&__img {
				position: absolute;
				width: 100%;
				height: 65%;
			}
		}
	}
}
