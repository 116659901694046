.template{
    position: relative;
    padding: 120px 6%;
}
.templateP{
    text-transform: uppercase;
    position: absolute;
    top: 7%;
    left: 6%;
    z-index: 1;
    color: var(--header-text);
}