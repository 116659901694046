$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.template3 {
	padding: 180px 6% 0px;
	&__heading {
		color: var(--black);
	}
	&__details {
		&__hr {
		}
		&__specifics {
			justify-content: space-between;
			margin: 80px 0px;
			&__carouselDiv {
				width: 40%;
				position: relative;
				&__carousel {
					width: 100%;
				}
				&__paragraph {
					text-transform: capitalize;
					color: var(--white);
					padding: 7px;
					font-size: 16px;
					background-color: var(--brand-primary-1);
					width: fit-content;
					position: absolute;
					top: 3%;
					left: -3%;
				}
			}
			&__text {
				width: 55%;
				&__heading {
					color: var(--header-text);
					font-size: 2.5rem;
				}
				&__description {
					color: var(--header-text);
					line-height: 1.2;
					width: 80%;
					font-size: var(--body-text-size);

					margin: 32px 0px 15px;
				}
				&__perks {
					&__intro {
						color: var(--header-text);
						font-size: var(--body-text-size);
					}
					&__list {
						margin-left: 25px;
						li {
							font-size: var(--body-text-size);
							color: var(--header-text);
							margin-top: 13px;
						}
					}
				}
				&__location {
					font-size: var(--body-text-size);
					color: var(--header-text);
					margin-top: 25px;
				}
				&__price {
					margin-top: 27px;
					color: var(--header-text);
					font-size: var(--body-text-size);
					span {
						color: var(--header-text);
						font-size: 1.9rem;
						font-family: "Ortica";
						font-weight: 100;
					}
				}
			}
		}
	}
}
.button {
	margin-top: 20px;
}
@media screen and (max-width: $breakpoint-tablet) {
	.template3 {
		> p {
			top: 3%;
		}
		padding: 180px 6% 0px;
		&__heading {
			font-size: 2.3rem;

			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 1.7rem;
			}
		}
		&__details {
			&__hr {
			}
			&__specifics {
				flex-direction: column-reverse;
				&__carouselDiv {
					width: 100%;
					margin-top: 70px;
				}
				&__text {
					width: 100%;
					&__heading {
						color: var(--header-text);
						font-size: 2rem;
						line-height: 1.1;
					}
				}
			}
		}
	}
	.button {
		width: 100%;
	}
}
