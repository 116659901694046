$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.template5 {
	&__top {
		margin-bottom: 180px;
		justify-content: space-between;
		@media screen and (min-width: $breakpoint-largeDesktop) {
			margin-bottom: 280px;
		}
		&__left {
			width: 55%;
		}
		&__right {
			width: 40%;

			p {
				color: var(--black);
				line-height: 1.6;
				margin-bottom: 40px;
			}
		}
	}
	&__bottom {
		position: relative;
		padding-bottom: 100px;
		&__images {
			position: absolute;
			top: -100px;
			width: 100%;
			justify-content: space-between;

			img {
				width: 20%;
			}
		}
	}
}

.template5__mobile {
	display: none;
}

@media screen and (max-width: $breakpoint-tablet) {
	.template5 {
		display: none;
	}
	.template5__mobile {
		display: flex;
		&__top {
			margin-bottom: 80px;
			justify-content: space-between;
			@media screen and (min-width: $breakpoint-largeDesktop) {
				margin-bottom: 280px;
			}
			&__left {
				width: 100%;
				&__heading {
					font-size: 2.3rem;

					@media screen and (max-width: $breakpoint-bigPhone) {
						font-size: 1.7rem;
					}
					line-height: 1.2;
				}
			}
			&__right {
				width: 100%;
				align-items: flex-end;
				p {
					color: var(--black);
					line-height: 1.6;
					margin: 40px 0px;
				}
			}
		}
		&__bottom {
			position: relative;
			padding-bottom: 10px;
			&__images {
				position: absolute;
				top: -28px;
				width: 100%;
				justify-content: space-between;
				img {
					width: 20%;
				}
			}
		}
	}
}

@media screen and (min-width: $breakpoint-mediumDesktop) {
	.template5 {
		&__bottom {
			&__images {
				top: -150px;
			}
		}
	}
}
@media screen and (min-width: $breakpoint-largeDesktop) {
	.template5 {
		&__bottom {
			&__images {
				top: -240px;
			}
		}
	}
}
