*,
::before,
::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
	color: var(--secondary-main);
	font-family: "Cerebri Sans Pro";
}

:root {
	/* Brand Colors */
	--brand-primary: #3b0e0b;
	--brand-primary-1: #d79d5d;
	--brand-primary-2: #b52200;
	--brand-primary-3: #022626;
	--brand-primary-4: #f0ccaa;
	--brand-primary-5: #bcc2b6;
	--brand-primary-6: #9b7850;
	--brand-primary-7: #f6f5e1;
	--brand-primary-8: #833e21;
	--brand-primary-9: #3b0e0b;
	--brand-primary-11: #21221a;

	/* Neutral Colours */
	--white: #f0f0f0;
	--black: #151611;

	/* Secondary Colours */
	--secondary-main: #e8e8e8;
	--secondary-main-2: #ffffff;

	/* Text Colours */
	--header-text: #0a0a0a;
	--body-text: #565656;

	/* Success */
	--success-background: #ddffe8;
	--success-text: #04802e;

	/* Selection Background */
	--selection-background: #e9e9e9;

	--body-text-size: 15px;
}
.row0 {
	display: flex;
	flex-direction: row;
}
.row1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.row2 {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.column0 {
	display: flex;
	flex-direction: column;
}
.column1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.column2 {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@font-face {
	font-family: "Cerebri Sans Pro";
	src: url("./fonts/Cerebri.Sans.Pro/CerebriSansPro-Regular.ttf")
		format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Ortica";
	src: url("./fonts/OrticaLinear-Light.otf") format("otf");
	font-weight: normal;
	font-style: normal;
}
.hidden {
	display: none;
}
