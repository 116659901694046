$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.template1 {
	background-color: var(--brand-primary-7);
	&__parent {
		position: relative;
		justify-content: space-between;
		&__left {
			width: 100%;
			height: 100%;
			&__paragraph {
				p {
					color: var(--black);
					font-size: 4vw;
					font-family: "Ortica";
				}
			}
			&__image {
				position: relative;
				width: 50%;
				&__div {
					position: absolute;
					p {
						font-family: "Ortica";
						color: var(--white);
						font-size: 4vw;
					}
				}
				img {
					width: 100%;
					height: 100%;
				}
				> p {
					color: var(--body-text);
					margin-top: 10px;
				}
			}
		}
		&__right {
			&__rotatingArrow {
				margin-bottom: 100px;
			}
		}
	}
	&__mobile {
		display: none;
	}
}
.statement {
	max-width: 70%;
}
@media screen and (max-width: $breakpoint-tablet) {
	.template1 {
		padding: 150px 6% 60px;
		&__parent {
			display: none;
		}
		&__mobile {
			display: flex;
			&__heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
				color: var(--header-text);
				line-height: 1.2;
			}
			&__image {
				margin-top: 60px;
				img {
					width: 100%;
					height: 100%;
				}
				> p {
					color: var(--body-text);
					margin-top: 25px;
					font-size: 16px;
				}
			}
			&__bottom {
				margin-top: 60px;
				justify-content: space-between;
				.statement {
					max-width: 40%;
					// font-size: 3.5rem;
					p {
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-bigPhone) {
	.template1 {
		&__mobile {
			&__heading {
				font-size: 1.9rem;
			}
		}
	}
}
