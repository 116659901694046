$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.heroComponent {
	padding: 230px 6% 70px;
	.mobile {
		display: none;
	}
	.test {
		position: relative;
		width: 100%;
	}
	&__top {
		width: 100%;
		&__statement {
			position: absolute;
			left: 0;
			.statement {
				max-width: 70% !important;
				@media screen and (max-width: $breakpoint-tablet) {
					> p {
						font-size: 0.6rem;
					}
				}
			}
		}
		&__headingImage {
			position: relative;
			width: 55%;
			&__heading {
				position: absolute;
				// top: -16%;
				width: 135%;
				&__head {
					line-height: 1.2 !important;
				}
			}
			&__image {
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
		&__rotatingArrow {
			position: absolute;
			right: 0;
		}
	}
	&__discoverArrow {
		margin-top: 60px;
	}
}

@media screen and (max-width: $breakpoint-tablet) {
	.heroComponent {
		.mobile {
			display: flex;
			&__heading {
				font-size: 2.3rem;

				color: var(--white);
				text-align: center !important;
				line-height: 1.2;
				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
			}
			&__image {
				margin-top: 60px;
				width: 90%;
				img {
					width: 100%;
					height: 100%;
				}
				> p {
					color: var(--white);
					margin-top: 25px;
					font-size: 16px;
				}
			}
			&__bottom {
				margin-top: 60px;
				justify-content: space-between;
				width: 100%;
				.statement {
					max-width: 35%;
				}
			}
		}
		.test {
			display: none;
		}
	}
}
@media screen and (max-width: $breakpoint-bigPhone) {
	.heroComponent {
		.mobile {
			&__bottom {
				.statement {
					max-width: 35%;

					p {
						font-size: 0.7rem;
					}
				}
			}
		}
	}
}
