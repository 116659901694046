$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.amenity {
	padding: 10px;
	border: 1px solid var(--header-text);
	.tickCircle {
        width: 20px;
        height: 20px;
	}
	p {
		text-transform: capitalize;
		color: var(--header-text);
		font-weight: 600;
		margin-left: 10px;
		font-size: 1rem;
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 0.8rem;
		}
	}
	@media screen and (max-width: $breakpoint-tablet) {
		justify-content: flex-start;
	}
}
