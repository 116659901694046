$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.locations {
	&__bottom {
		margin-top: 90px;
		&__heading {
			@media screen and (max-width: $breakpoint-tablet) {
				font-size: 2.3rem;
			}
			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 1.7rem;
			}
		}
		&__location {
			border-bottom: 1px solid var(--brand-primary-1);
			&__list {
				background-color: #e7e7e7a9;
				width: fit-content;
				@media screen and (max-width: $breakpoint-bigPhone) {
					flex-direction: column;
					width: 100%;
					text-align: center;
				}
				&__item {
					padding: 15px;
					transition: 0.5s;
					&:hover {
						background-color: var(--brand-primary-1);
						transition: 0.5s;
						span {
							// color: var(--white);
						}
					}
					span {
						text-transform: uppercase;
						color: var(--body-text);
						margin-right: 7px;
						font-size: 14px;
						font-weight: 500;
						@media screen and (max-width: $breakpoint-tablet) {
							font-size: 10px;
						}
					}
					list-style: none;
				}
			}
		}
	}
}
.marionApartments {
	justify-content: space-between;
	margin-top: 50px;
	&__left {
		width: 30%;
		img {
			width: 100%;
		}
		&__heading {
			font-size: 1.6rem;
			color: var(--black);
			margin: 20px 0px;
		}
		&__address {
			&__locator {
				width: 50px;
			}
			&__paragraph {
				color: var(--body-text) !important;
				font-size: 14px;
				line-height: 1.2;
			}
		}
		&__availableRooms {
			padding: 25px 0px;
			&__paragraph {
				font-size: 14px;
				color: var(--body-text) !important;
				padding: 0px 10px;
			}
		}
		&__a {
			color: var(--black);
			a {
				color: var(--black);
				margin-right: 5px;
			}
		}
	}
	&__right {
		width: 65%;
	}
}
.unavailable {
	margin-top: 40px;
}

@media screen and (max-width: $breakpoint-tablet) {
	.locations {
		&__bottom {
			&__heading {
				margin-bottom: 50px;
			}
		}
	}
	.marionApartments {
		flex-direction: column;
		&__left {
			width: 100%;
		}
		&__right {
			width: 100%;
			margin-top: 60px;
		}
	}
}
