.discover {
	text-transform: uppercase;
	p {
		font-size: 1.6rem;
		font-weight: 100;
		margin-bottom: 40px;
		font-family: "Ortica";
	}
    &__arrow {
        animation: rotation 0.8s infinite linear alternate;
    }
}

@keyframes rotation {
	from {
		transform: translateY(0px);
	}
	to {
		transform: translateY(-30px);
	}
}
