.circle {
	border-radius: 100%;
	height: 6px;
	width: 6px;
	span {
		visibility: hidden;
	}
}
.margin {
	margin: 0px 10px;
}
.noMargin {
	margin: 0px;
}
@media (max-width: 600px) {
	.circle {
		height: 9px;
		width: 9px;
	}
}
