$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.closingText {
	@media screen and (max-width: $breakpoint-tablet) {
		width: 90% !important;
        margin-bottom: 60px;
	}
	span {
		color: var(--header-text);
		font-family: "Ortica";
		font-size: 2.9rem;
	}
	p {
		color: var(--header-text);
		line-height: 1.5;
		font-weight: 100;
		font-size: 16px;
	}
	&__support {
		margin: 11px 0px 25px;
	}
	&__email {
		color: var(--black);
		font-weight: 600 !important;
		margin-bottom: 25px;
        a{
            text-decoration: none;
            color: var(--black);
        }
	}
	&__whatsapp {
		color: var(--black);
		font-weight: 600 !important;
	}
}
