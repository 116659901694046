$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.book {
	border-left: 1px solid var(--white);
	height: 80px;
	text-align: center;
	width: 14.166667%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-size: smaller;
	transition: 0.2s;
	transition: 0.3s;
	&:hover {
		transition: 0.3s;
		background-color: var(--brand-primary-1);
	}
	a {
		font-size: smaller;
		&:hover {
			border-bottom: none !important;
		}
	}
}
.navbar {
	position: fixed;
	z-index: 2;
	width: 100vw;
	text-transform: uppercase;
	text-decoration: none;
	align-items: center;
	font-family: "Cerebri Sans Pro", sans-serif;
	border-bottom: 2px solid rgba(255, 255, 255, 0.452);
	> div {
		width: 14.166667%;
		height: 80px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		a {
			text-decoration: none;
			font-size: smaller;
			transition: 0.2s;
			&:hover {
				border-bottom: 2px solid var(--white);
				transition: 0.2s;
			}
		}
	}
	&__image {
		width: 15% !important;
		> div {
			display: flex;
			justify-content: center;
			img {
				width: 80%;
			}
		}
	}
}
.mobile {
	display: none;
	transition: 1s;
	background-color: var(--brand-primary);
	position: fixed;
	z-index: 2;
	width: 100vw;
	text-transform: uppercase;
	&__top {
		justify-content: space-between;
		.button {
			width: 10%;
			@media screen and (max-width: $breakpoint-tablet) {
				width: 20%;
			}
		}
		&__logo {
			width: 50%;
			div {
				width: 50%;
				img {
					width: 100%;
				}
			}
		}
		.book {
			width: 25%;
			@media screen and (max-width: $breakpoint-bigPhone) {
				width: 35%;
			}
		}
	}
	.navbarMobile {
		border-top: 1px solid var(--white);
		z-index: 1;
		position: absolute;
		top: 100%;
		background-color: var(--brand-primary);
		width: 100%;
		padding: 15px 43px;
		font-family: "Cerebri Sans Pro", sans-serif;
		transition: 1s;
		a {
			margin: 7px 0px;
			padding: 13px 0px;
			text-decoration: none;
		}
	}
}
@media (max-width: 950px) {
	.navbar {
		a {
			font-size: xx-small;
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.navbar {
		display: none;
	}
	.mobile {
		display: flex;
		transition: 1s;
	}
}
.transparent {
	background-color: rgba(255, 255, 255, 0.346);
	background: linear-gradient(
			rgba(255, 255, 255, 0.1),
			rgba(255, 255, 255, 0.1)
		),
		linear-gradient(
			to bottom right,
			rgba(255, 255, 255, 0.1),
			rgba(255, 255, 255, 0.1)
		);
	// box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(11px);
	border: 2px solid rgba(255, 255, 255, 0.452);
	opacity: 1;
	transition: 1s;
}
.brown {
	background-color: #3b0e0b;
	transition: 1s;
}
.container {
	display: inline-block;
	cursor: pointer;
	.bar1,
	.bar2,
	.bar3 {
		width: 30px;
		height: 2px;
		background-color: white;
		margin: 4px 0;
		transition: 0.4s;
	}
}

.change .bar1 {
	transform: translate(0, 7px) rotate(-45deg);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	transform: translate(0, -5px) rotate(45deg);
}
.active {
	// background-color: #3b0e0b;
	border-bottom: 2px solid var(--white);
	transition: 0.2s;
	@media screen and (max-width: $breakpoint-tablet) {
		width: fit-content;
	}

	// a {
	// 	border-bottom: 2px solid var(--white);
	// 	transition: 0.2s;
	// }
}
