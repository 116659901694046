.container {
	display: inline-block;
	cursor: pointer;
	.bar1,
	.bar2,
	.bar3 {
		width: 30px;
		height: 2px;
		background-color: white;
		margin: 4px 0;
		transition: 0.4s;
	}
}

.change .bar1 {
	transform: translate(0, 7px) rotate(-45deg);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	transform: translate(0, -5px) rotate(45deg);
}
