.map {
	width: 100%;
	height: 100%;
	overflow: hidden;
	color: transparent !important;
	.mapboxgl-map {
		.mapboxgl-canvas {
			width: 100% !important;
			height: 100% !important;
		}
	}
	> div {
		height: 100%;
	}
}
