$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.beforeFooter {
	padding-top: 160px !important;
	&__top {
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 50px;
		&__paragraph {
			line-height: 1.6;
			width: 30%;
			@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
				width: 100%;
			}
			p {
				margin-bottom: 50px;
				color: var(--white);
			}
		}
	}
	&__bottom {
		position: relative;
		width: 100%;
		height: 950px;
		@media screen and (max-width: 1600px) {
			height: 850px;
		}
		@media screen and (max-width: $breakpoint-largeDesktop) {
			height: 750px;
		}
		@media screen and (max-width: $breakpoint-mediumDesktop) {
			height: 700px;
		}
		@media screen and (max-width: $breakpoint-desktop) {
			height: 570px;
		}
		@media screen and (max-width: $breakpoint-tablet) {
			height: 450px;
		}
		@media screen and (max-width: 650px) {
			height: 350px;
		}
		@media screen and (max-width: $breakpoint-bigPhone) {
			height: 300px;
		}
		&__layer1 {
			position: absolute;
			z-index: 1;
			left: 5%;
			color: #ffffff00;
		}
		&__layer2 {
			height: 100%;
			width: 130px;
			position: absolute;
			right: 42%;
			background-color: var(--brand-primary-9);
			align-items: flex-end;
		}
		img {
			position: absolute;
			width: 100%;
			top: 7%;
		}
	}
}
@media screen and (max-width: $breakpoint-desktop) {
	.beforeFooter {
		&__top {
			flex-direction: column;
			&__paragraph {
				width: 100%;
				> p {
					margin-top: 35px;
				}
				.button {
					width: 100%;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.beforeFooter {
		&__top {
			flex-direction: column;
			&__heading {
				font-size: 2.3rem !important;
				line-height: 1.2 !important;
			}
		}
		&__bottom {
			// height: 300px;
			&__layer1 {
				display: none;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-bigPhone) {
	.beforeFooter {
		&__top {
			&__heading {
				font-size: 1.8rem !important;
			}
		}
	}
}
