$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.secondHeroComponent {
	padding: 10% 70px 90px;
	&__top {
		justify-content: space-between;
		margin-bottom: 60px;
		&__heading {
			width: 75%;
		}
	}

	&__bottom {
		img {
			width: 100%;
		}
	}
	.mobileRotatingArrow {
		display: none;
	}
}

@media screen and (max-width: $breakpoint-tablet) {
	.secondHeroComponent {
		padding: 140px 6% 60px;
		align-items: center;
		&__top {
			&__heading {
				width: 100%;
				font-size: 2.3rem !important;
				line-height: 1.2;
				text-align: center !important;
			}
			&__rotatingArrow {
				display: none;
			}
		}

		&__bottom {
			img {
				width: 100%;
			}
		}
		.mobileRotatingArrow {
			display: block;
			margin-top: 60px;
		}
	}
}
@media screen and (max-width: $breakpoint-bigPhone) {
	.secondHeroComponent {
		&__top {
			&__heading {
				font-size: 1.8rem !important;
			}
		}
	}
}
