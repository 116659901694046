$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.template6 {
	padding-top: 90px;
	padding-bottom: 50px !important;
	background: url("../../../assets/images/carousel-background.jpg");
	align-items: flex-end;
	background-position: center;
	background-size: cover;

	&__statement {
		max-width: 15%;
	}
}
.template6__mobile {
	display: none;
}
@media screen and (max-width: $breakpoint-desktop) {
	.template6 {
		&__statement {
			max-width: 25%;
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.template6 {
		display: none;
	}
	.template6__mobile {
		background: url("../../../assets/images/carousel-background.jpg");
		display: flex;
		padding: 120px 6% 70px !important;
		&__bottom {
			margin-top: 50px;
			justify-content: space-between;
			width: 100%;
			&__statement {
				max-width: 35%;
				> p {
					font-size: 1.1rem;
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-bigPhone) {
	.template6__mobile {
		&__bottom {
			&__statement {
				> p {
					font-size: 0.9rem;
				}
			}
		}
	}
}
