.footer {
	background-color: var(--brand-primary);
	font-family: "Cerebri Sans Pro";
	&__top {
		padding: 70px 70px 100px 70px;
		justify-content: space-between;
		&__text {
			width: 40%;
			> div {
				line-height: 1.9;
				margin-bottom: 25px;
				h5 {
					text-transform: uppercase;

					font-weight: 700;
					font-size: 12px;
				}
				p {
					font-weight: 100;
					font-size: 14px;
				}
			}
		}
		&__footerLogo {
			width: 50%;
			img {
				width: 100%;
			}
		}
	}
	&__privacyAndCookies {
		padding: 30px 0px;
		border-top: 2px solid var(--brand-primary-1);
	}
}
@media (max-width: 768px) {
	.footer {
		// padding-top: 55px;
		&__top {
			flex-direction: column;
			align-items: flex-start;

			&__text {
				width: 100%;
				> div {
					margin-bottom: 45px;
				}
			}
			&__footerLogo {
				width: 100%;
				margin-top: 50px;
				display: flex;
				justify-content: center;
				img {
					width: 70%;
				}
			}
		}
	}
}

@media (max-width: 680px) {
	.footer {
		&__privacyAndCookies {
			font-size: 14px;
			flex-direction: column;
			align-items: center;
			.footerDot {
				display: none;
			}
		}
	}
}
@media (max-width: 480px) {
	.footer {
		&__top {
			padding: 60px 20px 50px 20px;
			&__text {
				font-size: 12px;
			}
		}
		&__privacyAndCookies {
			font-size: 12px;
		}
	}
}
