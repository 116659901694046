$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.reversed {
	flex-direction: row-reverse;
}

.testComponent {
	justify-content: space-between;
	&__text {
		width: 50%;
		&__paragraph {
			margin: 60px 0px 50px;
			line-height: 1.7;
			font-size: 1rem;
		}
		&__metric {
			margin-bottom: 42px;
			&__paragraph {
				text-transform: uppercase;
				margin-right: 40px;
				span {
					font-size: 2.8rem;
					font-weight: 100;
					font-family: "Ortica";
					margin-right: 13px;
				}
			}
		}
		&__transportPerks {
			justify-content: space-between;
			margin-bottom: 50px;
			width: 90%;
			&__perk {
				justify-content: space-between;
				p {
					margin-top: 12px;
					font-size: 14px;
					@media screen and (max-width:$breakpoint-bigPhone) {
						font-size: 12px
					}
				}
			}
		}
	}
	&__imageDiv {
		position: relative;
		width: 43%;
		img {
			width: 100%;
		}
		&__rotatingArrow {
			bottom: -60px;
			left: 35%;
			position: absolute;
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.testComponent {
		flex-direction: column;
		&__text {
			width: 100%;
			&__heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
				line-height: 1.2;
			}
			&__metric {
				margin-bottom: 0px;
				width: 100%;
				flex-direction: row;
				justify-content: space-between;
				&__paragraph {
					text-transform: uppercase;
					margin-right: 0px;
					font-size: 0.8rem;
					span {
						font-size: 2.3rem;

						@media screen and (max-width: $breakpoint-bigPhone) {
							font-size: 1.7rem;
						}
						font-weight: 100;
						font-family: "Ortica";
						margin-right: 13px;
					}
				}
			}
			.button {
				width: 100%;
				margin: 42px 0px;
			}
		}
		&__imageDiv {
			position: relative;
			width: 100%;
			img {
				width: 100%;
			}
			&__rotatingArrow {
				bottom: -60px;
				left: 35%;
				position: absolute;
			}
		}
	}
}
