.faqContainer {
	padding-top: 1.25rem;
    margin-bottom: 3rem;
    width: 100%;
}

.faqList {
	max-width: 800px;
	margin: auto;
}
.faqItem {
	margin-bottom: 8px;
	border-bottom: 1px solid #f3f3f3;
}
.faqBtn {
	width: 100%;
	text-align: left;
	padding: 1rem 0;
	background: transparent;
	color: var(--text-main);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border: none;
	cursor: pointer;
	font-weight: 500;
	font-size: var(--heading-4);

	* {
		transition: 0.3s;
	}

	svg {
		min-width: 32px;
	}
}
.faqBtn span {
	width: 80%;
	line-height: 1.5;
}
.faqBody {
	font-size: 14px;
	width: 95%;
	overflow: hidden;
	height: 0;
	transition: height 0.3s ease-out;
	color: var(--grey-2);
	line-height: 1.5;
	max-width: 520px;
}
.activeItem .faqBody {
	height: auto;
}
.inactiveItem .faqBody {
	animation-name: hideFaq;
}
.faqBody div {
	margin-bottom: 1.2rem;
}

@media screen and (max-width: 600px) {
	.ttl {
		font-size: var(--heading-3);
		text-align: left;
	}
	.txt {
		text-align: left;
		max-width: 340px;
		margin-bottom: 2rem;

		a {
			font-weight: inherit;
		}
	}
}
