$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.template7 {
	background-color: var(--brand-primary-6);
	&__top {
		justify-content: space-between;
	}
	&__bottom {
		justify-content: space-between;
		margin-top: 70px;
		&__image {
			position: relative;
			width: 25%;
			height: 550px;
			@media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-mediumDesktop) {
				width: 30%;
			}
			&__layer {
				position: absolute;
				width: 120px;
				background-color: var(--brand-primary-8);
				height: 100%;
				left: 10%;
			}
			img {
				position: absolute;
				width: 100%;
				height: 85%;
				top: 7%;
			}
		}
		&__faq {
			align-items: flex-end;
			width: 50%;
		}
	}
}
.template7__mobile {
	display: none;
}

@media screen and (max-width: $breakpoint-tablet) {
	.template7 {
		display: none;
	}
	.template7__mobile {
		padding: 180px 6% 70px;
		display: flex;
		background-color: var(--brand-primary-6);
		&__heading {
			font-size: 2.3rem;

			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 1.7rem;
			}
			line-height: 1.2;
		}
		&__top {
			justify-content: space-between;
			margin: 70px 0px;
			.statement {
				max-width: 30%;
			}
		}
		&__image {
			width: 50%;
			height: 550px;
			position: relative;
			@media screen and (max-width: $breakpoint-bigPhone) {
				width: 90%;
			}
			&__layer {
				position: absolute;
				width: 120px;
				background-color: var(--brand-primary-8);
				height: 100%;
				left: 10%;
			}
			img {
				position: absolute;
				width: 100%;
				height: 85%;
				top: 7%;
			}
		}
	}
}
