$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.template5 {
	background-color: var(--brand-primary-1);
	padding-left: 0px !important;
	padding-right: 0px !important;
	justify-content: space-between;
	padding-top: 180px !important;
	&__imageLeft {
		width: 25%;
		position: relative;
		height: 500px;
		&__layer {
			position: absolute;
			bottom: 0%;
			right: 5%;
			background-color: var(--brand-primary-2);
			width: 140px;
			align-items: flex-end;
			height: 110%;
			&__div {
				position: absolute;
				left: 15%;
				bottom: 6%;
			}
		}

		img {
			position: absolute;
			width: 100%;
			height: 70%;
		}
	}
	&__imageRight {
		width: 25%;
		height: 500px;
		img {
			width: 100%;
			height: 70%;
		}
	}
	&__text {
		text-align: center;
		width: 40%;
		margin-top: -150px;
		&__paragraph {
			margin-bottom: 50px;
			p {
				color: var(--black) !important;
				margin: 20px 0px;
			}
		}
	}
}
.template5__mobile {
	display: none;
}
@media screen and (max-width: $breakpoint-tablet) {
	.template5 {
		display: none;
	}
	.template5__mobile {
		background-color: var(--brand-primary-1);
		display: flex;
		flex-direction: column-reverse;
		padding: 170px 6% 70px;
		&__imageLeft {
			width: 100%;
			position: relative;
			height: 500px;
			margin-top: 100px;
			&__layer {
				position: absolute;
				bottom: 0%;
				right: 5%;
				background-color: var(--brand-primary-2);
				width: 140px;
				align-items: flex-end;
				height: 110%;
				&__div {
					position: absolute;
					left: 15%;
					bottom: 6%;
				}
			}

			img {
				position: absolute;
				width: 100%;
				height: 70%;
			}
		}
		&__imageRight {
			display: none;
		}
		&__text {
			text-align: center;
			width: 100%;

			&__heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
				line-height: 1.2;
			}
			&__paragraph {
				margin-bottom: 40px;
				p {
					color: var(--black) !important;
					margin: 15px 0px;
					font-weight: 500;
				}
			}
		}
	}
}
