$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.heroDiv {
	color: black;
	background-image: url("../../../assets/images/main-hero.jpg");
	background-size: cover;
	background-position: center center;
	min-height: 758px;
	height: 122vh;
	@media screen and (max-width: $breakpoint-tablet) {
		min-height: 400;
		height: 100vh;
	}
	&__header {
		max-width: 1050px;
		font-weight: 100;
		margin-top: 160px;
		line-height: 1.2;
		letter-spacing: 2px;
		font-size: 4.5rem;
		@media screen and (max-width: $breakpoint-tablet) {
			margin-top: 10px;
			font-size: 2.3rem;

			@media screen and (max-width: $breakpoint-bigPhone) {
				font-size: 1.7rem;
			}
			align-items: flex-start;
		}
	}
	&__input {
		align-items: flex-end;
		margin: 100px 0px;
		width: 100%;
		@media screen and (max-width: $breakpoint-tablet) {
			flex-direction: column;
			margin: 50px 0px;
			justify-content: center;
			align-items: center;
		}
		> div {
			@media screen and (max-width: $breakpoint-desktop) {
				width: 30%;
			}
			@media screen and (max-width: $breakpoint-tablet) {
				width: 90%;
			}
			label {
				font-size: 14px;
				margin-bottom: 10px;
			}
			input {
				border: 2px solid var(--white);
				padding: 16px;
				width: 300px;
				background-color: transparent;
				@media screen and (max-width: $breakpoint-desktop) {
					width: 100%;
				}
			}
			input[type="date"] {
				color: var(--white);
				font-size: 16px;
			}
		}
		&__checkOut {
			margin: 0px 20px;
			@media screen and (max-width: $breakpoint-tablet) {
				margin: 20px 0px;
			}
		}
		.heroButton {
			padding: 17px;

			@media screen and (max-width: $breakpoint-desktop) {
				width: 30%;
			}
			@media screen and (max-width: $breakpoint-tablet) {
				width: 90%;
			}
		}
	}
}
