.carouselContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	margin: 30px;
	.arrow {
		height: 70px;
		z-index: 1;
		top: 40%;
	}
	.prev {
		left: 20%;
	}
	.next {
		right: 20%;
	}
	.carouselSlide {
		transition: transform 0.5s, opacity 0.5s;
		opacity: 0.7;
		transform: scale(0.8);
		height: 100%;
		width: 30%;
		> div {
			height: 100%;
			width: 100%;
			background-color: rgba(51, 62, 62, 0.386);
			position: absolute;
			z-index: 1;
		}
		img {
			width: 100%;
		}
		p {
			display: none;
		}
	}
	.active {
		opacity: 1;
		transform: scale(1);
		> div {
			display: none;
		}
		p {
			display: block;
			margin-top: 10px;
		}
	}
}
