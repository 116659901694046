$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;
.template4 {
	background-color: var(--brand-primary-2);
	&__mobile {
		display: none;
	}
	&__parent {
		position: relative;
		&__heading {
			font-size: 4vw;
			position: absolute;
			z-index: 1;
			top: 5%;
			line-height: 1.2;
		}
		&__div {
			position: absolute;
			z-index: 1;
			left: 0;
			top: 50%;
			&__statement {
				max-width: 70%;
			}
		}
		&__image {
			width: 40%;
			height: auto;
			position: relative;
			&__rotatingArrow {
				position: absolute;
				right: -72px;
				bottom: 14%;
			}
			img {
				width: 100%;
			}
			> p {
				color: var(--white);
				margin-top: 7px;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-tablet) {
	.template4 {
		padding: 180px 6%;
		&__mobile {
			display: flex;
			&__heading {
				font-size: 2.3rem;

				@media screen and (max-width: $breakpoint-bigPhone) {
					font-size: 1.7rem;
				}
				color: var(--white);
				line-height: 1.2;
			}
			&__image {
				margin-top: 60px;
				img {
					width: 100%;
					height: 100%;
				}
				> p {
					color: var(--white);
					margin-top: 25px;
					font-size: 16px;
				}
			}
			&__bottom {
				margin-top: 60px;
				justify-content: space-between;
				.statement {
					max-width: 60%;

					p {
						font-size: 1.2rem;
					}
				}
			}
		}
		&__parent {
			display: none;
		}
	}
}
