$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.template1 {
	padding: 120px 7% 90px;
	height: 170vh;
	background-color: var(--brand-primary-2);
	&__heading {
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 2.3rem;
		}
		@media screen and (max-width: $breakpoint-bigPhone) {
			font-size: 1.7rem;
		}
	}
	hr {
		margin-bottom: 50px;
	}
	&__frame {
		width: 100%;
        height: 100%;
		iframe {
			width: 100%;
			height: 100%;
		}
	}
}
