.buttonLink {
	text-decoration: none;
	padding: 16px 0px;
	width: 300px;
	text-transform: uppercase;
	&__text {
		margin-right: 5px;
		font-size: 0.825rem;
		font-weight: 600;
	}
}

.white {
	border: 2px solid var(--white);
	&:hover{
		background-color: var(--white);
		span {
			color: var(--black);
		}
		.redirectBlack {
			display: block;
		}
		.redirectWhite {
			display: none;
		}
	}
	.buttonLink__text {
		color: white;
	}
	.redirectBlack {
		display: none;
	}
	.redirectWhite {
		display: block;
	}
}
.black {
	border: 2px solid var(--black);
	&:hover {
		background-color: var(--black);
		span {
			color: var(--white);
		}
		.redirectBlack {
			display: none;
		}
		.redirectWhite {
			display: block;
		}
	}
	.buttonLink__text {
		color: black;
	}
	.redirectBlack {
		display: block;
	}
	.redirectWhite {
		display: none;
	}
}
