$breakpoint-mobile: 320px;
$breakpoint-bigPhone: 520px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-mediumDesktop: 1280px;
$breakpoint-largeDesktop: 1440px;

.imageGroup {
	position: relative;
	&__overlay {
		height: 100%;
		width: 100%;
		&__div {
			position: relative;
			width: 33.333333%;
			&__layer {
				background-color: hsla(0, 0%, 0%, 0.601);
				height: 100%;
				width: 100%;
				position: absolute;
			}
			&__image {
				width: 100%;
				height: 100%;
			}
		}
	}
	&__images {
		width: 100%;
		img {
			width: 33.3333%;
		}
	}
	&__rotatingArrow {
		position: absolute;
		right: 5%;
		bottom: 8%;
	}
}
.mobileImageGroup {
	display: none;
}
@media screen and (max-width: $breakpoint-tablet) {
	.imageGroup {
		display: none;
	}
	.mobileImageGroup {
		display: flex;
		position: relative;
		&__overlay {
			height: 100%;
			width: 100%;
			&__div {
				position: relative;
				width: 100%;
				&__layer {
					background-color: hsla(0, 0%, 0%, 0.601);
					height: 100%;
					width: 100%;
					position: absolute;
				}
				&__image {
					width: 100%;
					height: 100%;
					margin-top: -1px;
				}
			}
		}
		&__images {
			width: 100%;
			img {
				width: 100%;
				margin-top: -2px;
			}
		}
		&__rotatingArrow {
			position: absolute;
			right: 5%;
			bottom: 3%;
		}
	}
}
